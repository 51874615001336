
 export default {
  "token": {
    "colorPrimary": "#0052d9",
    "borderRadius": 8,
    "wireframe": false,
    "fontSize": 16,
    "sizeStep": 6,
    "sizeUnit": 6,
    "colorSuccess": "#02bb62",
    "fontFamily": "Sora",
    "controlHeight": 48
  }
}