import common from './common'

import emotion from './emotion'
import accountReward from './accountReward'
import aiwatch from './aiwatch'

export const method = {
  common,

  emotion,
  accountReward,
  aiwatch
}